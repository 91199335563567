.find-peaks-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  height: 90vh;
  width: auto;

  margin: 0;
  padding: 0;
}

.find-peaks-container-no-data {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 90vh;
  width: auto;

  margin: 0;
  padding: 0;
}

.find-peaks-row-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100%;
}

.find-peaks-row-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#find-peaks-bounds {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#find-peaks-threshold {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 0;
}

.find-peaks-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 0;
}

#find-peaks-points {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 20px;
}

#find-peaks-points p {
  text-align: center;
}

#find-peaks-results {
  max-height: 80vh;

  overflow-y: scroll;
  overflow-wrap: normal;

  background-color: rgb(232, 232, 232);
  border-radius: 1.5em;
}

#find-peaks-text {
  padding: 5px 0 0 10px;
}

#find-peaks-data-container {
  margin: 20px;
}

#find-peaks-error {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  height: 80vh;

  margin: 50px;
}

@media screen and (max-width: 1000px) {
  .find-peaks-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: auto;
  }

  #find-peaks-bounds {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .find-peaks-box {
    margin: 10px;
  }

  #find-peaks-error {
    height: auto;
  }
}

/* ---------- instrument-window ---------- */
#instrument-window {
  display: flex;
}

#instrument {
  height: 90vh;
  flex-grow: 1;
}

#instrument-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36%;
  padding-bottom: 25px;
}
#instrument-error p {
  font-size: 30px;
  width: 50%;
  justify-self: center;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  #instrument-window {
    flex-direction: column;
  }

  #instrument {
    width: 100%;
  }

  #instrument-spinner {
    width: auto;
  }

  #instrument-error p {
    font-size: 20px;
  }
}

/* ---------- svg components ---------- */
.component {
  pointer-events: none;
}

.svg {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  border: #1d2022 2px solid;
}

g {
  pointer-events: all;
}

/* ---------- svg interactivity and animation ---------- */
#opd-value,
#manometer-value,
#molecule-value,
#range-value,
#resolution-value,
#scan-value,
#pressure-value {
  pointer-events: none;
}

/* ---------- tooltip ---------- */
.popup-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-tooltip h1 {
  font-size: 30px;
  font-family: "Montserrat";
  text-align: center;
  text-decoration: underline;
}

.tooltip-svg {
  height: auto;
  width: auto;

  max-width: 300px;
  max-height: 300px;

  padding: 10px;
}

.tooltip-graph {
  width: 500px;
  height: auto;
}

.tooltip-planck {
  width: 400px;
  height: auto;
}

.popup-tooltip p {
  font-size: large;
  font-family: "Roboto";
  text-align: center;
  padding: 3% 3%;
}

/* allows Spectra Display to take up full width */
#spectrum {
  width: 95vw;
}

@media screen and (max-width: 700px) {
  .popup-tooltip h1 {
    font-size: 20px;
  }

  .popup-tooltip p {
    font-size: 14px;
  }

  .MuiDialog-paper {
    width: 100%;
  }

  .tooltip-svg {
    max-width: 200px;
    max-height: 200px;
  }

  .tooltip-graph {
    width: 250px;
  }
}

.settings #graph-and-error {
  display: none;
}

.settings #experimental-setup {
  justify-content: center;
}

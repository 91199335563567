#open {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.open-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  align-self: center;
}

.file {
  height: 100%;
  overflow-y: scroll;
  overflow-wrap: normal;
  background-color: rgb(232, 232, 232);
  border-radius: 1.5em;
}

.content {
  padding: 0 1rem;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

/* ---------- popup ---------- */
.popup .MuiDialog-paper {
  height: 80% !important;
  width: 80%;
}

.popup h1 {
  font-family: "Montserrat";
  text-align: center;
  padding: 20px 0;
  text-decoration: underline;
}

.popup h2 {
  font-family: "Montserrat";
  text-align: center;
  text-decoration: underline;
}

.popup p {
  font-family: "Roboto";
  pointer-events: none;
  padding: 3% 3%;
}

.popup ol {
  font-family: "Roboto";
  padding: 0 6%;
}

.popup li {
  padding: 1.5% 0;
}

@media screen and (max-width: 700px) {
  .popup .MuiDialog-paper {
    width: 100%;
    padding: 0 4px;
  }

  /* .popup h1, */
  .popup h2 {
    font-size: 20px;
  }

  .popup p {
    font-size: 14px;
  }

  .popup li {
    font-size: 14px;
  }
}

/* ---------- fonts ---------- */
@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Regular.ttf);
}

/* ---------- experimental-setup ---------- */
#experimental-setup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-family: "Roboto";
}

/* ---------- parameter ---------- */
.parameter {
  margin: 10px;
}

/* ---------- form ---------- */
#form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 80%;
  align-self: start;
}

.exp-col {
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
}

.fetch-zone {
  display: flex;
  flex-direction: column;
  margin: auto;
}

#graph-and-error {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  overflow-x: auto;
  align-items: center;
  padding: 0;
}

/* ---------- spinner ---------- */
#spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ---------- error ---------- */
#error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

#error p {
  margin: 0;
  padding: 0;
}

/* ---------- Responsiveness ---------- */

@media only screen and (max-width: 1200px) {
  #form {
    max-width: 100%;
  }

  #experimental-setup {
    flex-direction: column;
  }

  #graph-and-error {
    width: 100%;
  }

  #button {
    margin-left: 25%;
  }
}

@media only screen and (max-width: 800px) {
  #form {
    flex-direction: column;
    justify-content: center;
  }

  .fetch-zone {
    height: 100px;
  }

  #button {
    margin-top: 40px;
    margin-left: 0;
  }
}

/* ---------- fonts ---------- */
@font-face {
  font-family: "Montserrat";
  src: url(./fonts/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Regular.ttf);
}

/* ---------- general ---------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* ---------- app ---------- */
.App {
  display: flex;
  flex-direction: column;
}

/* ---------- navigation / menu bar ---------- */
.nav-area {
  /* display: flex; */
  align-items: center;
  /* justify-content: left; */
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  background-color: #1d2022 !important;
  color: white;
  z-index: 1;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin: 0 20px 0 20px;
  padding-top: 10px;
  font-family: "Montserrat";
  /* align-self: center !important; */
}

.menus {
  display: flex;
  list-style: none;
}

.logolink {
  display: inherit;
}

.menu-items {
  background-color: #1d2022 !important;
  color: white !important;
}

.menu-items:hover {
  background-color: #2b3032 !important;
  color: rgb(246, 176, 106) !important;
}

.mini-menu .MuiDrawer-paper {
  background-color: #1d2022 !important;
}

.dropdown,
.MuiAccordionDetails-root {
  display: block;
  color: white;
  background-color: #1d2022 !important;
  /* padding: 0.7rem 1rem !important; */
  position: relative;
  font-size: 16px;
  width: 10rem;
}

.dropdown ul {
  padding: 0.5rem 0.4rem;
}

.dropdown a {
  font-size: inherit !important;
  color: inherit !important;
  text-decoration: none;
}

.dropdown button {
  color: inherit !important;
  font-size: inherit !important;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.dropdown p {
  padding: 0.5rem 0.2rem;
  text-align: center;
}

.dropdown-items:hover {
  background-color: #2b3032 !important;
  color: rgb(246, 176, 106) !important;
}

.dropdown-items button:hover:disabled {
  cursor: not-allowed;
}

.left-cluster {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.icon {
  margin: 0 4px;
}

a .icon {
  color: white;
}

.left-cluster a {
  padding-top: 8px;
}

.red {
  color: hsl(0, 80%, 50%);
}

.orange {
  color: hsl(39, 80%, 50%);
}

.yellow {
  color: hsl(60, 80%, 50%);
}

.green {
  color: hsl(110, 80%, 50%);
}

.teal {
  color: hsl(174, 80%, 50%);
}

.blue {
  color: hsl(240, 80%, 50%);
}

.indigo {
  color: hsl(275, 80%, 50%);
}

.purple {
  color: hsl(300, 80%, 50%);
}

@media screen and (max-width: 700px) {
  .logo {
    padding-top: 13px;
    font-size: 22px;
  }

  .dropdown,
  .MuiAccordionDetails-root {
    font-size: 14px;
  }

  .nav-area {
    padding: 0;
  }
}

.mini-menu .MuiDrawer-paper {
  background-color: #1d2022 !important;
}
